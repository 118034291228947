import React from 'react';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import { Switch } from 'antd';
import { useRecoilState } from 'recoil';
import { themeState } from '@bit/geografia.common.state';

const GeografiaLogo = styled.img`
  padding: 1rem;
  ${breakpoint('mobile')`
    width: 12rem;
    height: 4rem;
  `}
  ${breakpoint('tablet')`
    width: 12rem;
    height: 5rem;
  `}
  ${breakpoint('desktop')`
    width: 16rem;
    height: 6rem;
  `}
`;

const CouncilLogo = styled.img`
  padding: 1rem;
  ${breakpoint('mobile')`
    width: 10rem;
    height: 4rem;
    padding: 0.4rem;
  `}
  ${breakpoint('tablet')`
    width: 12rem;
    height: 6rem;
  `}
  ${breakpoint('desktop')`
    width: 15rem;
    height: 8rem;
  `}
`;

const ThemeSelection = styled.div`
  margin-left: auto;
`;

const HeaderContainer = ({clientName}) => {
  const [theme, setTheme] = useRecoilState(themeState);
  //console.log('theme', theme)
  const onChange = (checked) => {
    setTheme(checked);
  };
  return (
    <>
      <a target="_blank" href="/">
        <GeografiaLogo
          src={process.env.PUBLIC_URL + '/images/geografia-logo.svg'}
        />
      </a>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={`https://www.${clientName}.vic.gov.au/`}
      >
        <CouncilLogo
          src={
            process.env.PUBLIC_URL + `/images/${clientName}-logo.svg`
          }
        />
      </a>
      <ThemeSelection>
        <Switch checked={theme} onChange={onChange} />
      </ThemeSelection>
    </>
  );
};

export default HeaderContainer;
