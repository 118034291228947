import React, { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { Route, Switch, Redirect } from 'react-router-dom';
import _ from 'lodash';
import { PAGE_DIRECTORY_QUERY } from '../sqlQueries';
import { useHistory, useLocation } from 'react-router-dom';

// bit shared lib
import {
  sqlQueryTransforms,
  getAdjacentPageDirectory
} from '@bit/geografia.common.common';
import { chartPluginCityCompass } from '@bit/geografia.common.chart-plugin';
import Sidebar from '@bit/geografia.common.sidebar';
import CreateGlobalStyles from '@bit/geografia.common.create-global-styles';
import {
  StyledContent,
  StyledHeader,
  StyledFooter,
  StyledMain,
  StyledSidebar,
} from '@bit/geografia.common.styled-layout';

import { useApi } from '@bit/geografia.common.hooks';
import {
  currentUserNameQuery,
  themeState,
} from '@bit/geografia.common.state';
// Styled components
import { ThemeProvider } from 'styled-components';

// components
import GenericDataComponent from '../views/GenericDataComponent';
import ErrorPage from '../views/ErrorPage';
// container
import HeaderContainer from './HeaderContainer';
// antd components
import { Layout } from 'antd';
const { Sider, Content } = Layout;

// TODO: Do we weant the paths to be the A1B2 serial codes or the names of the pages?

const Root = ({ clientName }) => {
  let history = useHistory();
  let location = useLocation();
  chartPluginCityCompass();
  const clientDetails = useRecoilValue(
    currentUserNameQuery(clientName),
  );
  const clientTheme = JSON.parse(clientDetails.theme);
  const isThemeLight = useRecoilValue(themeState);
  let defaultPageCode, defaultPageMetaData;
  //console.log('clientDetails', clientDetails);
  let pageDirectory;
  const [pageMetaData, setPageMetaData] = useState(null);
  const [results, errorMessage] = useApi(PAGE_DIRECTORY_QUERY, {
    clientName,
    client_id: clientDetails.client_id,
  });
  let options, currentPageData;
  localStorage.setItem('clientName', clientName);
  if (results.length) {
    pageDirectory = sqlQueryTransforms(results);

    // TODO: refactor to not hard code path write utility belt for parsing the navigation structure
    // TODO: be explicit about the intial state of the pageMetadata using pagecode maybe?
    defaultPageCode = 'A1B1-home';
    // Find current page data and options
    const page_code_slug = _.last(location.pathname.split('/')).split(
      '-',
    );

    const page_code = page_code_slug[0];
    currentPageData = _.find(results, { page_code });
    options = _.has(currentPageData, 'options')
      ? currentPageData['options']
      : {};

    //console.log('results', results, 'pageDirectory', pageDirectory, 'currentPageData', currentPageData, 'options', options)
    if (currentPageData) {
      defaultPageMetaData = {
        page_titles: {
          a_title: currentPageData['a_title'],
          b_title: currentPageData['b_title'],
        },
        page_filters: currentPageData['page_filters'],
        ...options,
      };
    }
  }
  const handleMenuItemClick = ({
    item: {
      props: {
        data: { pageMetaData, page_code, slug },
      },
    },
  }) => {
    if (history.location.pathname !== `/${clientName}/${page_code}`) {
      slug
        ? history.push(`/${clientName}/${page_code}-${slug}`)
        : history.push(`/${clientName}/${page_code}`);
      setPageMetaData(pageMetaData);
    }
  };

  return errorMessage ? (
    <p>{errorMessage}</p>
  ) : pageDirectory ? (
    <ThemeProvider
      theme={
        isThemeLight ? clientTheme.lightTheme : clientTheme.darkTheme
      }
    >
      <CreateGlobalStyles />
      <Layout style={{ minHeight: '100vh' }}>
        <StyledHeader>
          <HeaderContainer clientName={clientName} />
        </StyledHeader>

        <StyledContent>
          <Layout>
            <StyledSidebar>
              <Sider width={270} breakpoint="lg" collapsedWidth="0">
                <Sidebar
                  pageDirectory={pageDirectory}
                  handleMenuItemClick={handleMenuItemClick}
                />
              </Sider>
            </StyledSidebar>
            <StyledMain>
              <Content style={{ padding: '0 24px', minHeight: 280 }}>
                <Switch>
                  <Route
                    exact
                    path={`/${clientName}`}
                    render={() => (
                      <Redirect
                        to={`/${clientName}/${defaultPageCode}`}
                      ></Redirect>
                    )}
                  ></Route>
                  <Route
                    path={`/${clientName}/:route_page_code_slug`}
                    render={({
                      match: {
                        params: { route_page_code_slug },
                      },
                    }) => {
                      const route_page_code = route_page_code_slug.split(
                        '-',
                      )[0];
                      return currentPageData ? (
                        <GenericDataComponent
                          key={route_page_code}
                          clientName={clientName}
                          client_id={clientDetails.client_id}
                          pageMetaData={
                            pageMetaData || defaultPageMetaData
                          }
                          page_code={route_page_code}
                          page_title={currentPageData.page_title}
                          adjacentPages={getAdjacentPageDirectory(
                            pageDirectory,
                            route_page_code,
                          )}
                          handleMenuItemClick={handleMenuItemClick}
                          options={options}
                        />
                      ) : (
                        <Route component={ErrorPage} />
                      );
                    }}
                  />
                  <Route component={ErrorPage} />
                </Switch>
              </Content>
            </StyledMain>
          </Layout>
        </StyledContent>
        <StyledFooter>
          Geografia Pty. Ltd. © {new Date().getFullYear()}
        </StyledFooter>
      </Layout>
    </ThemeProvider>
  ) : null;
};

export default Root;
