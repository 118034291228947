import React, { useReducer } from "react";
import 'url-search-params-polyfill';
import { Layout, Menu } from "antd";
import { PAGE_CONTENT_QUERY } from "../sqlQueries";
// bit import
import { santaizeSql, convertAjdPageToMetaData, pageDepth, gridColumns } from "@bit/geografia.common.common";
import {useApi, useWindowDimensions} from '@bit/geografia.common.hooks';
import PageContent from "@bit/geografia.common.page-content";
import PageFilters from "@bit/geografia.common.page-filters";
import PageBreadcrumb from "../components/elements/PageBreadcrumb";
import Table from "@bit/geografia.common.table";
import TableGroup from "@bit/geografia.common.table-group";
import BarChart from '@bit/geografia.common.bar-chart';
import LineChart from '@bit/geografia.common.line-chart';
import PyramidChart from '@bit/geografia.common.pyramid-chart';
import Map from "@bit/geografia.common.map";

import PageFiltersContext from "@bit/geografia.common.page-filters-context";
import { useLocation } from "react-router-dom";
import filtersReducer from '../reducers/filtersReducer';
import styled from "styled-components";
import _ from 'lodash';

const { Content } = Layout;
const PageTitle = styled.h1`
  font-size: 3rem;
  margin: 1rem 0;
` 

const GenericDataComponent = ({
  page_code,
  page_title,
  pageMetaData,
  adjacentPages,
  clientName,
  client_id,
  handleMenuItemClick,
  options
}) => {
  // initial state
  const query = new URLSearchParams(useLocation().search);
  const initSelectedFilters = Array.from(query.entries()).reduce(
    (memo, [key, value]) => {
      memo.push({ [key]: value });
      return memo;
    },
    []
  );
  const { width } = useWindowDimensions();
  const [selectedFilters, dispatch] = useReducer(filtersReducer, initSelectedFilters)
  const [results, errorMessage] = useApi(PAGE_CONTENT_QUERY, {
    clientName,
    client_id,
    page_code,
  })
  const groups = _.groupBy(results, 'element_order')
  //console.log('results',results,'groups', groups, 'page_code', page_code, 'adjacentPages', adjacentPages)
  return errorMessage ? 
  <p>{errorMessage}</p> : 
  ( <Content>
      {pageDepth(page_code) === 4 ? (
        <Menu
          onClick={handleMenuItemClick}
          selectedKeys={[page_code]}
          mode="horizontal"
        >
          {/* TODO: change adjacentPages to match the pageMetaData schema */}
          {adjacentPages.map((adjPage) => (
            <Menu.Item key={adjPage.page_code} data={convertAjdPageToMetaData(adjPage)}>
              {adjPage.d_title}
            </Menu.Item>
          ))}
        </Menu>
      ) : null}
      <PageTitle>{page_title}</PageTitle>
      <PageBreadcrumb titles={pageMetaData.page_titles} />
      {pageMetaData["page_filters"] && (
        <PageFiltersContext.Provider
          value= {
            { selectedFilters,
              dispatch }
          }
        >
          <PageFilters pageFilters={pageMetaData["page_filters"]} options={options}/>
        </PageFiltersContext.Provider>
      )}
      {
        Object.keys(groups).map( (group, groupIndex) => 
            <div key={`group-${groupIndex}`} className="grid"> 
              {  groups[group].map((page, index) => {
                  //NOTE: depend in element_type then render appropriate component type
                  // 'text' => render PageContent
                  // 'chart' => render Chart
                  let gridColumn = page.element_position === 'left' ? `1/${page.element_width+1}`: `${gridColumns - page.element_width +1}/-1`
                  if (width < 737) {
                    gridColumn = '1/-1'
                  } 
                  return {
                    text: (
                      <PageContent
                        key={`text-${page_code}-${index}`}
                        header={page.element_header}
                        footer={page.element_footer}
                        content={page.element_text}
                        query={santaizeSql(page.data_query)}
                        selectedFilters={selectedFilters}
                        position={page.element_position}
                        gridColumn={gridColumn}
                      />
                    ),
                    table: (
                      <Table
                        key={`table-${page_code}-${index}`}
                        header={page.element_header}
                        footer={page.element_footer}
                        query={santaizeSql(page.data_query)}
                        selectedFilters={selectedFilters}
                        position={page.element_position}
                        gridColumn={gridColumn}
                      />
                    ),
                    'tableGroup':(
                      <TableGroup
                        key={`table-${page_code}-${index}`}
                        header={page.element_header}
                        footer={page.element_footer}
                        query={santaizeSql(page.data_query)}
                        options={page.options}
                        selectedFilters={selectedFilters}
                        position={page.element_position}
                        gridColumn={gridColumn}
                      />
                    ),
                    'horizontalBarChart': (
                      <BarChart
                        key={`chart-${page_code}-${index}`}
                        header={page.element_header}
                        footer={page.element_footer}
                        query={santaizeSql(page.data_query)}
                        options={page.options}
                        selectedFilters={selectedFilters}
                        chartType="horizontalBarChart"
                        position={page.element_position}
                        gridColumn={gridColumn}
                      />
                    ),
                    'verticalBarChart': (
                      <BarChart
                        key={`chart-${page_code}-${index}`}
                        header={page.element_header}
                        query={santaizeSql(page.data_query)}
                        options={page.options}
                        selectedFilters={selectedFilters}
                        chartType="verticalBarChart"
                        position={page.element_position}
                        gridColumn={gridColumn}
                      />
                    ),
                    'pyramidChart': (
                      <PyramidChart
                        key={`chart-${page_code}-${index}`}
                        header={page.element_header}
                        query={santaizeSql(page.data_query)}
                        options={page.options}
                        selectedFilters={selectedFilters}
                        position={page.element_position}
                        gridColumn={gridColumn}
                      />
                    ),
                    'lineChart': (
                      <LineChart
                        key={`chart-${page_code}-${index}`}
                        header={page.element_header}
                        query={santaizeSql(page.data_query)}
                        options={page.options}
                        selectedFilters={selectedFilters}
                        position={page.element_position}
                        gridColumn={gridColumn}
                        project="citycompass"
                      />
                    ),         
                    map: (
                      <Map
                        key={`map-${page_code}-${index}`}
                        content={page.element_text}
                        query={santaizeSql(page.data_query)}
                        options={page.options}
                        selectedFilters={selectedFilters}
                        position={page.element_position}
                        gridColumn={gridColumn}
                      />
                    ),
                  }[page.element_type];
                })
              }
            </div>
        )
      }
        



    </Content>
  );
};

export default GenericDataComponent;
