export const PAGE_DIRECTORY_QUERY = `/* PAGE_DIRECTORY_QUERY */
  SELECT
      a_level,
      a_title,
      b_level,
      b_title,
      c_level,
      c_title,
      d_level,
      d_title,
      page_code,
      page_filters,
      page_title,
      options,
      notes,
      slug
  FROM
      cc_pagedirectory_{{client_id}}
  ORDER BY
      page_code`;

// element_position: whether block appears on left side or right side of screen. see: https://github.com/geografia-au/citycompass/issues/43
// element_width: how wide block should be relative to parent container in grid units (<= 6). see: https://github.com/geografia-au/citycompass/issues/43
export const PAGE_CONTENT_QUERY = `/* PAGE_CONTENT_QUERY */
  SELECT
      page_code,
      element_order,
      element_type,
      element_header,
      element_text,
      element_footer,
      element_position,
      element_width,
      data_query,
      options
  FROM
      cc_pagecontent_{{client_id}}
  WHERE
      page_code = '{{page_code}}'
  ORDER BY
      element_order`;